@tailwind components;
@tailwind utilities;

body, html { 
    font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

::-webkit-scrollbar {
    display: none;
}